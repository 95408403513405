
    @import "config.scss";
 
.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-modal);
}

.modalContainer {
  z-index: 100000;
  background-color: var(--color-grey);
  margin-inline: 5vw;
  border: 2px solid var(--color-white);
  border-radius: 25px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-inline: 40px;
  padding-block: 45px;
  height: 22rem;
  .logo {
    margin-bottom: 10px;
    h4 {
      span {
        width: 4.5vw !important;
        height: 2.9vw !important;
        margin-bottom: 2px;
      }
    }
  }
  p {
    margin-bottom: 15px;
    text-align: center;
  }
}

.modal {
  background-color: #00000080;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}
