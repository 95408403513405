
    @import "config.scss";
 
.container {
  margin-bottom: 8rem;

  .title {
    margin-inline: 2rem;
    margin-bottom: 5rem;

    @include for-desktop-up {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .text {
      text-align: center;
      margin-bottom: 1rem;
      margin-right: 25px;

      @include for-desktop-up {
        margin-block: auto;
      }
    }
  }

  .desktop {
    display: flex;
    justify-content: space-evenly;
  }
}
