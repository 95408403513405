
    @import "config.scss";
 
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.titleWrapper {
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  width: 100%;
}

.iconEnd {
  justify-content: space-between;
  margin-right: 1rem;
}
