
    @import "config.scss";
 
.form {
  color: white;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .subtitle {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
  }

  .contentLine {
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 100%;

    @include for-desktop-up {
      flex-direction: row;
      align-items: center;
    }

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      // margin-right: 1rem;

      @include for-desktop-up {
        margin-bottom: 0px;
      }
    }
  }

  .modificarButton {
    width: 100%;
    margin-bottom: 50px;

    button {
      color: var(--color-primary);
      background-color: transparent;
      border: none;
      display: flex;
      cursor: pointer;
    }

    @include for-desktop-up {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  .submit {
    width: 100%;
  }

  .addressMobile {
    flex-direction: column;
    font-weight: 400;

    @include for-desktop-up {
      display: none;
    }
  }

  .addressDesktop {
    display: none;

    @include for-desktop-up {
      display: flex;
      margin-left: 4vw;
    }
  }
}

.scroll {
  height: 100vh;
  overflow: hidden;
}
