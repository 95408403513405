
    @import "config.scss";
 
.container {
  // padding-inline: 10px;
  padding-block: 25px;

  a {
    scroll-snap-align: start;
  }

  .title {
    padding-inline: 20%;
    margin-bottom: 25px;

    @include for-desktop-up {
      padding-inline: 5%;
      display: flex;
      justify-content: space-between;
    }
  }
  .withLink {
    padding-inline: 5%;
    display: flex;
    justify-content: space-between;
  }
}
