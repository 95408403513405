
    @import "config.scss";
 
.review-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-block: 0.5rem;

  .number {
    margin-right: 2px;
    margin-bottom: 0;
  }
}
.stars-review {
  display: flex;
  gap: 0.2rem;
}

.errorMessage {
  display: block;
  border-color: var(--color-error);
  margin-top: 4px;
  font-weight: 700;
}

.scoring {
  &:hover {
    color: #ffd0006e;
  }
}

.star {
  width: 30px;
  height: 30px;
  background-size: cover;
  // margin: 28px auto;
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.full-star {
  background: #ffd100;
}

.half-star {
  background: linear-gradient(270deg, #c8c8c8 50%, #ffd100 50%);
}

.empty-star {
  background: #c8c8c8;
}
