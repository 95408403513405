
    @import "config.scss";
 
.container {
  width: 100%;
  padding: 25px 20px 0px 20px;

  @include for-desktop-up {
    padding: 25px 20vw 0px 20vw;
  }
}

.title {
  margin-bottom: 30px;

  .head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.confirmData {
  background-color: var(--color-background-container);
  border-radius: 25px;
  padding: clamp(12px, 8vw, 30px) clamp(12px, 8vw, 30px); // 8vw 7vw;
  margin-bottom: 25px;
}

.formData {
  background-color: var(--color-background-container);
  border-radius: 25px;
  padding: clamp(12px, 8vw, 30px) clamp(12px, 8vw, 30px);
  margin-bottom: 25px;
}

.confirminput {
  margin-bottom: 25px;

  p {
    margin-right: 15px;
  }

  @include for-desktop-up {
    display: flex;
  }
}

.sections {
  border-radius: 25px;
  margin-bottom: 25px;
  background-color: var(--color-background-container);
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  padding: 20px 18px;
}

.icons {
  display: flex;

  p {
    margin-left: 10px;
  }
}

.keypad {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  flex-direction: column;

  @include for-desktop-up {
    flex-direction: row;
  }
}
