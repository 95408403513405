
    @import "config.scss";
 
.form {
  color: white;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .contentLine {
    margin: 0px;
    display: flex;
    flex-direction: column;

    // @media (min-width: 1200px) {
    @include for-desktop-up {
      flex-direction: row;

      div {
        width: 50%;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    margin-block: 0px;
    margin-top: 1.5rem;
    gap: 1rem;
    align-items: center;

    @include for-desktop-up {
      flex-direction: row;
      margin-top: 20px;

      button {
        margin-bottom: 0px;
        // margin-right: 70px;
      }
    }
  }

  div {
    margin-block: 12px;
    font-weight: bold;
    display: flex;
    align-items: baseline;

    p {
      font-weight: normal;
      margin-left: 5px;
      color: var(--color-white-dark);
    }
  }
}

.modal {
  background-color: #00000080;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.scroll {
  height: 100vh;
  overflow: hidden;
}

.modalContainer {
  z-index: 100000;
  background-color: var(--color-grey);
  margin-inline: 5vw;
  border: 2px solid var(--color-white);
  border-radius: 25px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-inline: 40px;
  padding-block: 45px;

  @include for-desktop-up {
    top: 25%;
    left: 17%;
  }

  p {
    margin-bottom: 15px;
    text-align: center;
  }
}
