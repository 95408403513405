
    @import "config.scss";
 
.container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  @include for-desktop-up {
    justify-content: center;
    flex-direction: row;
  }
}
