
    @import "config.scss";
 
.header {
  background-color: var(--color-background-container);
}

.firstLine {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  z-index: var(--z-nav-fixed);
  h4 {
    font-size: 7vw !important;
    span {
      width: 10vw !important;
      height: 5.1vw !important;
    }
  }
  @include for-desktop-up {
    h4 {
      font-size: 3vw !important;
      span {
        width: 5vw !important;
        height: 2.1vw !important;
      }
    }
  }
}
