
    @import "config.scss";
 
.box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  justify-content: space-between;
}
@include for-desktop-up {
  .box {
    max-width: fit-content;
    min-width: 300px;
  }
}
