
    @import "config.scss";
 
.box {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  border-left: 1px solid #707070;
  @include for-desktop-up {
    border-bottom: 1px solid #707070;
    border-left: none;
  }
  .valoration {
    display: flex;
    flex-direction: column;
  }
}

.divider {
  border: 1px solid;
}
