
    @import "config.scss";
 
.form {
  color: white;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  .subtitle {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }

  .contentLine {
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 100%;

    @include for-desktop-up {
      flex-direction: row;
      align-items: center;
    }

    .item {
      display: none;
      align-items: center;
      margin-bottom: 25px;

      @include for-desktop-up {
        margin-bottom: 0px;
        display: flex;
        margin-left: 8%;
      }

      svg {
        color: var(--color-primary);
      }
    }
  }

  .modificarButton {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;

    button {
      color: var(--color-primary);
      background-color: transparent;
      border: none;
      display: flex;
    }

    @include for-desktop-up {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      width: 50%;
    }
  }

  .submit {
    width: 100%;
    margin-top: 20px;
  }

  .addressMobile {
    flex-direction: column;
    font-weight: 400;

    @include for-desktop-up {
      margin-left: 5%;
      width: 65%;
    }
  }
}

.scroll {
  height: 100vh;
  overflow: hidden;
}

.icon {
  color: var(--color-primary);
  width: 48;
  height: 48;
}
